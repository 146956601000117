<template>
  <div class="revoke-card-key">
    <en-table-layout :tableData="tableData.data">
      <template slot="toolbar">
        <div class="col toolbar-title">
          撤销记录
        </div>
        <div class="col-auto">
          <el-button type="primary" size="small" @click="importShopGoods()" class="mr-2">
            撤销卡密
          </el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="撤销时间">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column prop="create_user_name" label="撤销人员" />
        <el-table-column prop="success_num" label="撤销数据量" />
        <el-table-column label="操作" width="210px">

          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="downLoad(scope.row)">
              下载撤销数据
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="tableData.page_size" :total="tableData.total" />
    </en-table-layout>
    <import-cardKey @downloadError="downloadError" ref="importCardKey" @imported="handleCurrentChange(1)" />
  </div>
</template>

<script>
import importCardKey from "./components/importCardKey.vue"
import * as API_Goods from '@/api/goods';
import { revokeSelfDefinedTemplate } from '@/utils';
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
export default {
  components: { importCardKey, EnTableLayout, },
  data () {
    return {
      params: {
        page_size: 20,
        page_no: 1,
      },
      tableData: {
        data: []
      }
    }
  },
  mounted () {
    this.getList();
  },
  methods: {
    /**打开导入商品弹窗 */
    importShopGoods () {
      this.$refs.importCardKey.show();
    },
    /**
     * 操作列下载撤销数据
     * @param {Object} row 当前行数据
     */
    downLoad (row) {
      API_Goods.revokeRecordDetail(row.id).then((resp) => {
        console.log(resp);
        const { shop_name, card_name, revoke_cards_key_list } = resp;
        let importData = [];
        revoke_cards_key_list.forEach(item => {
          importData.push({
            card_code: item.card_code,
            card_key: item.card_key
          })
        })
        // 下载数据
        let tHeaders = [], filterVals = [];
        tHeaders = ['卡号', '卡密'];
        filterVals = ['card_code', 'card_key'];
        revokeSelfDefinedTemplate(
          shop_name,
          card_name,
          importData,
          tHeaders,
          filterVals,
          '撤销卡密记录',
          true
        );
      });
    },
    /**下载导入商品失败数据 */
    downloadError ({ sum, ok, no, errorExport }) {
      let msg = `本次共导入${sum}条数据，其中导入成功${ok}条，失败${no}条。是否要下载导入失败的数据表？`
      this.$confirm(msg, '提示', {
        type: 'warning'
      }).then(() => {
        this.handleExportSelectedList(errorExport)
      });
    },
    // 下载失败数据
    handleExportSelectedList (failGoodsData) {
      let a = document.createElement('a');//创建a标签
      a.href = failGoodsData;//文件url
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();//触发下载
      document.body.removeChild(a);
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.getList();
    },
    getList (page_no) {
      let params = this.params;
      page_no && (params.page_no = 1);
      API_Goods.revokeRecords(params).then((resp) => {
        this.tableData = {
          data: resp.data,
          page_no: resp.page_no,
          page_size: resp.page_size,
          total: resp.data_total,
        };
      });
    }
  }
}
</script>