<template>
  <x-dialog :proxy="importDialog">
    <div class="mt-3">
      <h3 style="color: #333">第一步：填写导入数据信息</h3>
      <div class="tip">
        <p>1、请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除。</p>
        <p>2、表格中字段均为必填项。</p>
      </div>
      <el-button type="text" @click="downTemplate">下载表格模板</el-button>
    </div>
    <div class="mt-3" style="margin-bottom: 10px;">
      <h3 style="color: #333">第二步：上传填好的信息表</h3>
      <div class="tip">
        文件后缀名必须为 <b>.xls</b> 或 <b>.xlsx</b>（即 <b>Excel</b> 格式），最多支持导入5千条数据。
      </div>
      <upload-excel-component defaultSize :on-success-tem="excelSuccess" @upFileName='e => { this.tooltipContent = e }' />
      <el-tooltip v-if="tooltipContent" class="item" effect="dark" :content="tooltipContent" placement="top-start">
        <el-button class="add-camilo-file" v-text="tooltipContent"></el-button>
      </el-tooltip>
    </div>
  </x-dialog>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';
import { selfDefinedTemplate } from '@/utils';
import * as API_Goods from '@/api/goods';
import UploadExcelComponent from '@/components/UploadExcel';
export default {
  name: 'import-member',
  components: {
    XDialog,
    UploadExcelComponent
  },
  data () {
    return {
      tooltipContent: '',
      importDialog: $xDialog.create({
        title: '导入操作',
        wrapperClass: 'import-member-dialog',
        beforeConfirm: () => this.uploadData(),
      }),
      isExistErrorData: false,
      submitImportCardKeysParams: {
        card_name: '',
        shop_name: '',
        cards_key_dolist: []
      },
    }
  },
  methods: {
    show () {
      this.tooltipContent = ''
      this.submitImportCardKeysParams = {
        card_name: '',
        shop_name: '',
        cards_key_dolist: []
      }
      this.importDialog.display();
    },
    async excelSuccess ({ results, shop_name, card_name }) {
      this.isExistErrorData = true;
      this.submitImportCardKeysParams.cards_key_dolist = [];
      this.submitImportCardKeysParams.shop_name = shop_name
      this.submitImportCardKeysParams.card_name = card_name
      results.map((item, index) => {
        this.submitImportCardKeysParams.cards_key_dolist.push({
          card_code: (item['卡号']).toString(),
          card_key: (item['卡密']).toString(),
        });
      });
      this.isExistErrorData = false;
    },
    uploadData () {
      return new Promise(resolve => {
        if (!this.submitImportCardKeysParams.cards_key_dolist.length && !this.isExistErrorData) {
          this.$message.error('请导入数据');
          resolve(false);
        } else if (this.submitImportCardKeysParams.cards_key_dolist.length > 5000) {
          this.$message.error('导入数据不能超过5000条');
          resolve(false);
        } else if (!this.submitImportCardKeysParams.cards_key_dolist.length && this.isExistErrorData) {
          this.$message.error('导入的Excel内可能存在必填项没有填写或存在重复数据，请检查表格确认无误后重新导入');
          resolve(false);
        } else {
          API_Goods.revokeCardsKeys(this.submitImportCardKeysParams).then((res) => {
            const no = res.false, ok = res.success, errorExport = res.file, sum = this.submitImportCardKeysParams.cards_key_dolist.length;
            if (no === 0) {
              this.$message.success('操作成功');
              resolve(true);
              this.$emit('imported');
            } else {
              console.log(no);
              this.$emit('imported');
              let datas = {
                sum,
                ok,
                no,
                errorExport
              }
              this.$emit('downloadError', datas)
              resolve(true);
            }
          });
        }
      });
    },
    downTemplate () {
      // 下载模板
      let tHeaders = [],
        filterVals = [];
      this.MixinSetShopExtAuthList().then(res => {
        if (res.toHiddenAuth.includes('shareVideoList')) {
          tHeaders = ['卡号', '卡密'];
        } else {
          tHeaders = ['卡号', '卡密', '卡号前缀'];
        }
        filterVals = ['xxxx', 'yyyy'];
        selfDefinedTemplate(
          this.integralImportData,
          tHeaders,
          filterVals,
          '卡密导入模板'
        );
      })
    },
  }
}
</script>

<style lang="scss">
.special-reminder {
  padding: 0;

  .el-alert {
    padding: 16px;
  }
}

.import-member-dialog {
  .tip {
    font-size: 13px;
    color: #666;
    margin-bottom: 5px;
  }
}

.add-camilo-file {
  padding: 8px;
  background-color: rgba(231, 228, 228, 0.6);
  border-radius: 4px;
  border: none;
}
</style>
